<template>
  <div class="product-page">
    <div class="round-right"></div>
    <div class="round-left"></div>
    <div class="container">
      <div class="product-block-title">
        DJOON - тренажер менеджеров по продажам с последующим трудоустройством
      </div>
      <div class="product-block-desc colored-back">
        Пока все образовательные сервисы обещают рынку сомнительных экспертов, мы гарантируем крепких джунов.
      </div>
      <div class="product-block-desc">
        Мы обучаем минимально необходимым навыкам для трудоустройства, а бизнес получает сотрудников, готовых к бою, уже
        через месяц обучения на нашей платформе!
      </div>
<!--      <div class="product-img-djoon-1" :style="{ 'background-image': `url('/css/images/DJOON-1.png')` }">-->

<!--      </div>-->
      <el-image
          style="width: 100%; height: 100%"
          src="/css/images/products/DJOON-1.png"
          fit="fit"></el-image>
      <div class="product-block-subtitle">
        Нас много!
      </div>
      <div class="product-block-desc">
        Почти 50% всех выпускников ВУЗ-ов и колледжей начинали свой путь в продажах. А раз так, почему бы не научиться
        делать это правильно! - по статистике, кто прошел учебную подготовку по продажам, зарабатывает на 50% больше
        тех, кто начал продавать «с чистого листа»)
      </div>
<!--      <div class="product-img-djoon-2" :style="{ 'background-image': `url('/css/images/products/DJOON-2.png')` }">-->

<!--      </div>-->
      <el-image
          style="width: 100%; height: 100%"
          src="/css/images/products/DJOON-2.png"
          fit="fit"></el-image>


      <div class="product-block-subtitle">
        Обучение
      </div>
      <div class="product-block-desc">
        Обучение проходит по нашей собственной авторской методике, включает
        в себя софт скиллы и хард скиллы
      </div>
      <ul class="product-block-skills">
        <li v-for="skill in skills">
          {{ skill }}
        </li>
      </ul>
<!--      <div class="product-img-djoon-3" :style="{ 'background-image': `url('/css/images/products/DJOON-3.png')` }">-->

<!--      </div>-->
      <el-image
          style="width: 100%; height: 100%"
          src="/css/images/products/DJOON-3.png"
          fit="fit"></el-image>

      <div class="product-block-subtitle">
        Преимущества обучения
      </div>
      <div class="product-block-desc">
        Мы подаем сложные вещи максимально просто
      </div>
      <ul class="product-block-skills">
        <li v-for="advantage in advantages">
          {{ advantage }}
        </li>
      </ul>
      <div class="product-banner">
        Нужны сотрудники? Ищешь работу? Нужно трудоустроить студентов?
        <div class="product-banner-join">Присоединяйся к нам!</div>
      </div>
    </div>
  </div>
</template>


<script>

import Vue from "vue";

export default {
  name: 'Products',
  data() {
    return {
      currentTab: "all",
      skills: [
        'Изучение основ продаж',
        'Формирование УТП',
        'Работа с воронками продаж',
        'Основы построения контакта с клиентом',
        'Навыки самопрезентации',
        'Презентация продукта/услуги',
        'Работа с CRM системой',
        'Работа с системой телефонии',
        'Основы переговоров',
        'Базы работы со скриптами разговоров',
        'Основа работы с холодными звонками',
        'Работа с возражениями',
      ],
      advantages: [
        'Без учителей',
        'Без домашних заданий',
        'Без дедлайнов',
        'Без вебинаров',
      ]
    }
  },
}
</script>
<style>

</style>
